import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, Form } from '@angular/forms';
import { GlobalService } from '../shared/global.service';
import { CustomValidators } from 'ngx-custom-validators';
import { HttpClient } from '@angular/common/http';
import { element } from 'protractor';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Router } from '@angular/router';
import mapStyle from '../shared/googleMapStyles.json';
import { NavigationactiveService } from '../shared/navigation/navigationactive.service';

declare var google: any;
interface selectedDepartment {
  id: number,
  department: string
}

@Component({
  selector: 'app-doctorregister',
  templateUrl: './doctorregister.component.html',
  styleUrls: ['./doctorregister.component.css'],
})

export class DoctorregisterComponent implements OnInit {
  basicDetailsForm: FormGroup;
  contactDetailsForm:FormGroup;
  clinicDetailsForm:FormGroup;
  clinicForm:FormGroup;
  authForm:FormGroup;
  departmentsArray:any[];
  clinicImages: Array<object> = [];
  clinicImageBoolean: boolean = false;
  doneForm:FormGroup;
  updateBoolean:boolean=false;
  latitude:number;
  longtitude:number;
  mapstyles = mapStyle;
  previous;
  formatAddress:string;
  profileBase64:string='https://cdn1.vectorstock.com/i/thumb-large/75/85/default-placeholder-doctor-half-length-portrait-vector-20847585.jpg';
  logoBase64: string = 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQd5dc777ct6nkp4IyzNT6x0mZLz5TnygTna0bh8re9sKYJSMOp';
  constructor(private activeService:NavigationactiveService,private fb: FormBuilder, private globalService: GlobalService, private http: HttpClient,private router:Router) {

  }

  ngOnInit() {
    this.activeService.activePageLoad.next('register');
    this.departmentsArray = [
      'Psychiatry',
      'Urology',
      'Gynaecology',
      'others',
      'Dentist',
      'General',
      'Gastrology',
      'Pediatrician',
      'Ophthalmology',
      'Dermatology',
      'Cardiology',
      'Oncology'
    ];
    this.basicDetailsForm=this.fb.group({
      firstName:['',[Validators.required]],
      lastName:[''],
      qualification:['',[Validators.required]],
      department:['',[Validators.required]],
      dob:[''],
      experience:['',[Validators.required]],
      service:['',[Validators.required]],
      fee:['',[Validators.required]],
      profilePicture:['']
    });
    this.contactDetailsForm=this.fb.group({
      mobileNumber:['',[Validators.required,Validators.minLength(10)]],
      emailId:['',[Validators.required,Validators.email]],
      landLine:[''],
      address1:['',[Validators.required]],
      address2:[''],
      city:['',[Validators.required]],
      state:['',[Validators.required]],
      country:['',[Validators.required]],
      postalCode:['']
    });
    this.clinicDetailsForm=this.fb.group({
      clinicName:[''],
      logo:[''],
      clinicImage:['']
    });
    this.authForm=this.fb.group({
      myRecaptcha:['']
    })
  }

  openpopupInfo(infoWindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infoWindow;
  }

  resolved(captchaResponse: string) {
      if(captchaResponse!==null)
      { 
        this.updateBoolean=true;
        this.authForm.get('myRecaptcha').clearValidators();
        this.authForm.get('myRecaptcha').updateValueAndValidity();
        this.latlng();
      }
      else
      {
        this.updateBoolean=false;
      }
  }

  latlng()
  {
    this.globalService.getLatLan(this.contactDetailsForm.value.address1 + ',' + this.contactDetailsForm.value.address2 + ',' + this.contactDetailsForm.value.city + ',' + this.contactDetailsForm.value.state + ',' + this.contactDetailsForm.value.country + ',' + this.contactDetailsForm.value.postalCode)
        .subscribe(latlanres => {
      this.latitude=latlanres.results[0].geometry.location.lat;
      this.longtitude=latlanres.results[0].geometry.location.lng;
      this.formatAddress=latlanres.results[0].formatted_address;
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  handleProfileInput(files: FileList) {
    let file = files.item(0);
    if (file) {
      if (file.size <= 256000) {
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          let reader = new FileReader();
          reader.onload = this._handleProfileReaderLoaded.bind(this);
          reader.readAsBinaryString(file);
        }
      }
      else
      {
        this.basicDetailsForm.controls['profilePicture'].setErrors({'large': 'Memory Size Large'});
      }
    }
  }

  _handleProfileReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.profileBase64 = 'data:image/jpg;base64,' + btoa(binaryString);
  }

  handleLogoInput(files: FileList) {
    let file = files.item(0);
    if (file) {
      
      if (file.size <= 256000) {
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          let reader = new FileReader();
          reader.onload = this._handleLogoReaderLoaded.bind(this);
          reader.readAsBinaryString(file);
        }
      }
      else
      {
        this.clinicForm.controls['logo'].setErrors({'large': 'Memory Size Large'});
      }
    }
  }

  _handleLogoReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.logoBase64 = 'data:image/jpg;base64,' + btoa(binaryString);
  }

  handleclinicInput(files: any) {
    this.clinicImages = [];
    let endValue = 5;
    for (let i = 0; i < endValue; i++) {
      const file = files.item(i);
      if (file) {
        if (file.size <= 256000 && (file.type === 'image/jpeg' || file.type === 'image/png')) {
          var reader = new FileReader();
          reader.onload = this._handleClinicReaderLoaded.bind(this);
          reader.readAsBinaryString(files.item(i));
          this.clinicImageBoolean = true;
        }
        else {
          endValue++;
        }
      }
    }
  }
  
  _handleClinicReaderLoaded(readerEvt) {
    var binaryString = btoa(readerEvt.target.result);
    console.log(`data:image/jpg;base64,${binaryString}`);
    this.clinicImages.push({ 'image': `data:image/jpg;base64,${binaryString}` });
  }

  deleteImage(key) {
    delete this.clinicImages[key];
  }

  createDomain()
  {
    const departmentNameArray = this.departmentsArray[parseInt(this.basicDetailsForm.value.department) - 1];
    const tempData = {
      firstName:this.basicDetailsForm.value.firstName,
      lastName:this.basicDetailsForm.value.lastName!==undefined?this.basicDetailsForm.value.lastName:'',
      qualification:this.basicDetailsForm.value.qualification,
      department:this.basicDetailsForm.value.department,
      dob:this.basicDetailsForm.value.dob,
      experience:this.basicDetailsForm.value.experience,
      service:this.basicDetailsForm.value.service,
      fee:this.basicDetailsForm.value.fee,
      logo:this.logoBase64,
      clinicName:this.clinicDetailsForm.value.clinicName,
      emailId:this.contactDetailsForm.value.emailId,
      mobileNumber:this.contactDetailsForm.value.mobileNumber,
      landLine:this.contactDetailsForm.value.landLine,
      address1:this.contactDetailsForm.value.address1,
      address2:this.contactDetailsForm.value.address2,
      city:this.contactDetailsForm.value.city,
      state:this.contactDetailsForm.value.state,
      country:this.contactDetailsForm.value.country,
      postalCode:this.contactDetailsForm.value.postalCode,
      departmentName:departmentNameArray,
      lat:this.latitude,
      lan:this.longtitude,
      formatAddress:this.formatAddress,
      logoBlob:this.logoBase64,
      profilePictureBlob:this.profileBase64
    }

    this.globalService.doctorRegistartion(tempData)
      .subscribe(res => {
        if (res.status === 'success') {
          this.clinicImageUpload(res.doctorId);
        }
      })
  }

  clinicImageUpload(doctorId) {
    this.clinicImages.map((element,key) => {
      this.globalService.postClinicImage(doctorId, element)
        .subscribe(res => {
          console.log(res);
          if(key+1===this.clinicImages.length)
          {
            window.history.go(-1);
          }
        });
    });
  }

}
