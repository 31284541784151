import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DoctorviewpageService {
  ViewPageLoadingData = new BehaviorSubject<string>("none");

  viewPageObservable = this.ViewPageLoadingData.asObservable();
  
  constructor() { }

  updateData(data)
  {
    this.ViewPageLoadingData.next(data);
  }
}
