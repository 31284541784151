import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from './navigation.service';
import { GlobalService } from '../global.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { NavigationactiveService } from './navigationactive.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})

export class NavigationComponent implements OnInit {
  visibleLogin:boolean;
  isLogged: boolean;
  patientName:string;
  patientId:number;
  profilePicture:string;
  activeHome:string;
  activeDashboard:string;
  activeRegister:string;
  activeLogin:string;
  
  constructor(private activeService:NavigationactiveService,private navigationService:NavigationService,private globalService: GlobalService, private router:Router) { 
    this.visibleLogin= true;
  }
  ngOnInit() {
      this.activeService.activePageObservable.subscribe(res=>{
        this.disableValue();
        if(res==='home')
        {
          this.activeHome='active';
        }
        else if(res==='dashboard')
        {
          this.activeDashboard='active';
        }
        else if(res==='register')
        {
          this.activeRegister='active';
        }
        else if(res==='login')
        {
          this.activeLogin='active';
        }
      })
      
      this.navigationService.navigationObservable.subscribe(data=>{
      this.isLogged = this.globalService.getloginDetails();
      
      if(this.isLogged)
      {
        const storageData=JSON.parse(localStorage.getItem("loginCredentials"));
        this.patientName=storageData.patientName;
        this.globalService.getPatientImage(storageData.patientId).subscribe(data=>{
          this.profilePicture=data.profilePicture;
        });
        this.patientId = storageData.patientId;
      }
    })
  }

  disableValue()
  {
    this.activeHome=this.activeLogin=this.activeRegister=this.activeDashboard='';
  }
  hiddenBtn(){
    this.visibleLogin = false;
  }
 
  removeStorage()
  {
    localStorage.removeItem("loginCredentials");
    this.navigationService.navigationPageLoadData.next(false);
    this.router.navigate(['/search']);
  }
}
