import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FavouritesComponent } from './favourites.component';
import { FavouritesService } from '../shared/globalResolver.service';
import { FavouritesModule } from './favourites.module';


const routes: Routes = [{
  path:'',
  component:FavouritesComponent,
  resolve:{
    favourites:FavouritesService
  }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers:[FavouritesService]
})
export class FavouritesRoutingModule { }
