import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ForgotpinRoutingModule } from './forgotpin-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ForgotpinComponent } from './forgotpin.component';
import { GlobalService } from '../shared/global.service';
import { RecaptchaModule } from 'ng-recaptcha';


@NgModule({
  declarations: [
    ForgotpinComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ForgotpinRoutingModule,
    RecaptchaModule
  ],
  exports :[
    ForgotpinComponent
  ]
})
export class ForgotpinModule { }
