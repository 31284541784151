import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';
import { take, map } from 'rxjs/operators';

@Injectable()
export class DoctorListResolverService {
  constructor(private globalService: GlobalService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.globalService.getDoctorList().pipe(take(1), map((res: any) => {
      return res.doctor;
    }));
  }
}

@Injectable()
export class CityListResolverService {
  constructor(private globalService: GlobalService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.globalService.getCityList().pipe(take(1), map((res: any) => {
      return res.city;
    }));
  }
}

@Injectable()
export class DoctorDetailsResolverService {
  constructor(private globalService: GlobalService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.globalService.getDocDetails(route.params.id).pipe(take(1), map((res: any) => {
      return res.doctorDetails;
    }));
  }
}

@Injectable()
export class DoctorAppointmentSlotsResolverService {
  constructor(private globalService: GlobalService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.globalService.getDoctorsAppointmentSlots(route.params.id).pipe(take(1), map((res: any) => {
      return res.availableDays;
    }));
  }
}

@Injectable()
export class PatientDashboardService {
  constructor(private globalService: GlobalService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const storageData=JSON.parse(localStorage.getItem("loginCredentials"));
    return this.globalService.getPatientAppointmentData(storageData.patientId).pipe(take(1), map((res: any) => {
      return res.patientAppointmentData;
    }));
  }
}

@Injectable()
export class FavouritesService {
  constructor(private globalService: GlobalService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const storageData=JSON.parse(localStorage.getItem("loginCredentials"));
    return this.globalService.getFavourites(storageData.patientId).pipe(take(1), map((res: any) => {
      return res.favourites;
    }));
  }
}
