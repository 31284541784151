import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationactiveService {
  
  activePageLoad=new BehaviorSubject<string>('home')
  activePageObservable = this.activePageLoad.asObservable();

  constructor() { }
}
