import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { GlobalService } from '../shared/global.service';
import { MapsAPILoader, MouseEvent, GoogleMapsAPIWrapper } from '@agm/core';
import publicIp from 'public-ip';
import { NavigationactiveService } from '../shared/navigation/navigationactive.service';
declare var google: any;
interface CityList {
  location_id: number;
  city_name: string;
  city_latitude: number;
  city_longitude: number;
  state: string;
  country: string;

};



interface doctorDetailsList {
  doctorId: number;
  doctorName: string;
  mobileNo: number;
  mailId: string;
};

@Component({
  selector: 'app-search-doctor',
  templateUrl: './search-doctor.component.html',
  styleUrls: ['./search-doctor.component.css']
})

export class SearchDoctorComponent implements OnInit {
  doctorsData: any[];
  private geoCoder;
  cityData: any[];
  city: CityList[];
  cityModelValue: CityList;
  searchWord: doctorDetailsList[];
  searchModelValue: doctorDetailsList;
  latitude;
  longitude;

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  focusDoctorBox$ = new Subject<string>();
  clickDoctorBox$ = new Subject<string>();
  constructor(private activeService:NavigationactiveService,private route: ActivatedRoute, private globalService: GlobalService, private router: Router,
    private mapsAPILoader: MapsAPILoader
  ) { }
  
  ngOnInit() {
    this.activeService.activePageLoad.next('home');
    this.globalService.mapLoadingData.next(' Default Data ');
    this.loadData();
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      }
      );
    }
    else {
      this.ipAddress('');
    }
  }

  async ipAddress(params) {
    const ip = await publicIp.v4();
    this.globalService.getIPAddressLatLan(ip)
      .subscribe(data => {
        this.latitude = data.geoplugin_latitude;
        this.longitude = data.geoplugin_longitude;
        this.currentLocationDoctors(params);
      });
  }

  loadData() {
    this.route.data.subscribe(res => {
      this.doctorsData = res.doctors;
      this.cityData = res.city;
      let tempData = {
        "location_id": '0',
        "city_name": 'Current Location',
        "city_latitude": 1,
        "city_longitude": 1,
        "state": '',
        "country": ''
      };
      this.cityData.unshift(tempData);
    });
  }

  /**
   * City Typeahead Format
   */
  cityFormatter = (city: CityList) => city.city_name;
  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.cityData
        : this.cityData.filter(city => city.city_name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  /**
   * Docotor Typeahead Format
   */
  doctorFormatter = (searchWord: doctorDetailsList) => searchWord.doctorName;
  doctorSearch = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickDoctorBox$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focusDoctorBox$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.doctorsData
        : this.doctorsData.filter(doctor => doctor.city_name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  /**
   * City Change Method
   */
  cityChange(value: any) {

    let paramsValue = '';
    if (value.location_id !== 0) {
      paramsValue = value.location_id;
    }
    else {
      paramsValue = 'false';
    }
    if (value.location_id !== undefined) {
      if (value.location_id != 0) {
        this.globalService.getDoctorListCityWise(paramsValue)
          .subscribe(data => {
            data.status !== 'success'
              ?
              console.log(" Citywise doctors list getting error occured..!")
              :
              this.doctorsData = data.cityWiseDoctors;

            console.log(data.cityWiseDoctors);
            this.globalService.mapLoadingData.next(value.location_id)
          })
      }
      else {
        this.currentLocationDoctors(paramsValue)
      }
    }
  }

  /**
   * Doctor box change method
  */
  doctorChange(value: any) {
    value.doctorId !== undefined
      ?
      this.router.navigate(['doctor', value.doctorId])
      :
      null
  }

  currentLocationDoctors(params) {
    if (this.latitude !== undefined && this.longitude !== undefined) {
      if (params !== '') {
        this.globalService.getCurrentLocationDoctor(this.latitude, this.longitude)
          .subscribe(data => {
            if (data.status !== 'success') {
              console.log('Error Occured while retriving Current Location Doctors');
            }
            else {
              console.log(data.currentLocationdoctors);
              this.doctorsData = data.currentLocationdoctors;
              this.globalService.mapLoadingData.next(params);
            }
          });
      }
    }
    else {
      this.ipAddress(params);
    }
  }

}
