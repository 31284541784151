import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PatientappointmentdataRoutingModule } from './patientappointmentdata-routing.module';
import { PatientappointmentdataComponent } from './patientappointmentdata.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [PatientappointmentdataComponent],
  imports: [
    CommonModule,
    PatientappointmentdataRoutingModule,
    FormsModule
  ],
  exports:[PatientappointmentdataComponent]
})
export class PatientappointmentdataModule { }
