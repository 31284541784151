import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  navigationPageLoadData = new BehaviorSubject<boolean>(false);
  navigationObservable = this.navigationPageLoadData.asObservable();

  constructor() { }
  
}
