import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientDashboardRoutingModule } from './patient-dashboard-routing.module';
import { PatientDashboardComponent } from './patient-dashboard.component';
import { FormsModule } from '@angular/forms';
import { PatientappointmentdataModule } from '../patientappointmentdata/patientappointmentdata.module';
import { PatientprofileModule } from '../patientprofile/patientprofile.module';
import { FavouritesModule } from '../favourites/favourites.module';


@NgModule({
  declarations: [PatientDashboardComponent],
  imports: [
    CommonModule,
    PatientDashboardRoutingModule,
    FormsModule,
    PatientappointmentdataModule,
    PatientprofileModule,
    FavouritesModule
  ],
  exports: [PatientDashboardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PatientDashboardModule { }
