import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchDoctorComponent } from './search-doctor.component';
import { DoctorListResolverService, CityListResolverService } from '../shared/globalResolver.service';

const routes: Routes = [
  {
    path: '',
    component: SearchDoctorComponent,
    resolve: {
      doctors: DoctorListResolverService,
      city: CityListResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [DoctorListResolverService, CityListResolverService]
})
export class SearchDoctorRoutingModule { }
