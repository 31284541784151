import { Component, OnInit } from '@angular/core';
import { DoctorregisterwebviewService } from './doctorregisterwebview/doctorregisterwebview.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {
  title = 'tocdorapp';
  isVisible:boolean;
  constructor(private doctorregisterService:DoctorregisterwebviewService){
    this.isVisible=true;
  }

  ngOnInit()
  {
    this.doctorregisterService.navigationShownObservable.subscribe(res=>{
      this.isVisible=res;
    });
    
  }

}
