import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { GlobalService } from '../shared/global.service';
@Injectable({
  providedIn: 'root'
})
export class CheckcredentialsService implements CanActivate {

  constructor(private globalService :GlobalService,public router: Router) { }
  canActivate(): boolean {
    const storageValue=this.globalService.getAuthDetails();
     if (storageValue===null) {
       this.router.navigate(['/login']);
       return false;
    }
    return true;
  }
}
