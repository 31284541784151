import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotpinComponent } from './forgotpin.component';

const routes: Routes = [{
  path:'',
  component:ForgotpinComponent,
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ForgotpinRoutingModule { }
