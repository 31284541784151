import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class GetapiService {

  webapiURL: string;
  header: any;

  constructor(private httpClient: HttpClient) {
    this.webapiURL = 'http://localhost:8000';
    this.header = new HttpHeaders().set('Content-Type', 'application/json');
  }

  // get doctor list
  //get ip address values
  
}
