import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { enableProdMode } from '@angular/core';

import { MatAutocompleteModule, MatInputModule } from '@angular/material';
import { MatFormFieldModule, MatRippleModule, MatButtonModule } from '@angular/material';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglemapRoutingModule } from './googlemap-routing.module';
import { GooglemapComponent } from './googlemap.component';
import { GetapiService } from './getapi.service';
import { Ng5SliderModule } from 'ng5-slider';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
enableProdMode();

@NgModule({
  declarations: [GooglemapComponent],

  imports: [
    CommonModule,
    GooglemapRoutingModule,
    HttpClientModule,
    Ng5SliderModule,
    AgmJsMarkerClustererModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyANoBjaYZxWrQNdEZN6C0jdO9yAoDoWvLo',
      libraries: ['places']
    })
  ],
  exports: [GooglemapComponent],
  providers: [GetapiService],
  bootstrap: [GooglemapComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class GooglemapModule { }
