import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../shared/navigation/navigation.service';
import { GlobalService } from '../shared/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationactiveService } from '../shared/navigation/navigationactive.service';
interface storageData {
  patientId: number,
}

interface reviewdAppointment {
  patientId: number,
  doctorId: number,
  appointmentId: number
}
@Component({
  selector: 'app-patient-dashboard',
  templateUrl: './patient-dashboard.component.html',
  styleUrls: ['./patient-dashboard.component.css']
})
export class PatientDashboardComponent implements OnInit {
  storageData:storageData;
  profilePicture:string;
  dashboardActive: string;
  profileActive: string;
  favouriteActive: string;

  constructor(private globalService: GlobalService,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private activeService:NavigationactiveService
  ) {
    
    this.storageData = JSON.parse(localStorage.getItem("loginCredentials"));
    this.globalService.getPatientImage(this.storageData.patientId)
      .subscribe(data => {
        this.profilePicture = data.profilePicture;
      })
  }
  prescriptionLoading: boolean;
  visible:boolean=true;
  prescriptionData: any[];

  ngOnInit() {
    this.activeService.activePageLoad.next('dashboard');
    this.dashboardActive='active';
  }

  activeComponent(name) {
    this.visible=false;
    if(name==='favourite')
    {
      this.dashboardActive = this.profileActive = '';
      this.favouriteActive = 'active';
      this.visible=true;
    }
    else if(name==='profile')
    {
      this.dashboardActive = this.favouriteActive = '';
      this.profileActive = 'active';
      this.visible=true;
    }
    else
    {
      this.dashboardActive = 'active';
      this.favouriteActive = this.profileActive = '';
      this.visible=true;
    }
  }

  removeStorage()
  {
    localStorage.removeItem("loginCredentials");
    this.navigationService.navigationPageLoadData.next(false);
    this.router.navigate(['/search']);
  }

}
