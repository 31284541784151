import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../shared/global.service';
interface storageData {
  patientId:number
}

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.css']
})

export class FavouritesComponent implements OnInit {
  doctorsList:any[];
  storageData:storageData;
  visible:boolean=false;
  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private globalService:GlobalService
  ) {
    this.storageData=JSON.parse(localStorage.getItem('loginCredentials'));
   }

  ngOnInit() {
    this.globalService.getFavourites(this.storageData.patientId)
    .subscribe(res=>{
      if(res.status==='success')
      {
        this.doctorsList=res.favourites;
        this.visible=true;
      }
      else
      {
        console.log('Error Occured while getting Favourites Data')
      }
    })
  }

}
