import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationModule } from './shared/navigation/navigation.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { GooglemapModule } from './googlemap/googlemap.module';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { MatIconModule } from '@angular/material';
import { SearchDoctorModule } from './search-doctor/search-doctor.module';
import { ForgotpinModule } from './forgotpin/forgotpin.module';
import { CustomFormsModule } from 'ngx-custom-validators';
import { PatientDashboardModule } from './patient-dashboard/patient-dashboard.module';
import { PatientprofileModule } from './patientprofile/patientprofile.module';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { DoctorregisterModule } from './doctorregister/doctorregister.module';
import { DoctorregisterwebviewComponent } from './doctorregisterwebview/doctorregisterwebview.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PatientappointmentdataComponent } from './patientappointmentdata/patientappointmentdata.component';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ScrollToModule.forRoot(),
    BrowserModule,
    MatIconModule,
    AppRoutingModule,
    GooglemapModule,
    NavigationModule,
    CustomFormsModule,
    ForgotpinModule,
    PatientDashboardModule,
    BrowserAnimationsModule,
    SearchDoctorModule,
    PatientprofileModule,
    DoctorregisterModule,
    AgmJsMarkerClustererModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyANoBjaYZxWrQNdEZN6C0jdO9yAoDoWvLo',
      libraries: ['places']
    })
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

