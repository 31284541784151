import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatientDashboardComponent } from './patient-dashboard.component';
import { PatientDashboardService } from '../shared/globalResolver.service';


const routes: Routes = [
  {
    path: '',
    component: PatientDashboardComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [PatientDashboardService]
})
export class PatientDashboardRoutingModule { }
