import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class DoctorregisterwebviewService {
  navigationShown = new BehaviorSubject<boolean>(true);
  navigationShownObservable = this.navigationShown.asObservable();

  constructor() { }
}
