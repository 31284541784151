import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  url: string;
  public mapLoadingData = new BehaviorSubject<string>(' Default Data ');
  mapLoadingInjectVariable = this.mapLoadingData.asObservable();
  
  constructor(
    private http: HttpClient,
  ) {
    this.url = 'http://localhost:8000';
    // this.url = 'http://192.168.43.68:8000';
  }

  getDoctorList() {
    return this.http.get(`${this.url}/doctor`);
  }

  getCityList() {
    return this.http.get<any>(`${this.url}/city`);
  }

  getDoctorListCityWise(id) {
    return this.http.get<any>(`${this.url}/citywisedoctors?cityId=${id}`);
  }

  getDocDetails(docId: number) {
    return this.http.get(`${this.url}/doctordetails/${docId}`);
  }

  getCurrentLocationDoctors(lat, long) {
    return this.http.get(`${this.url}/currentlocationdoctors?latitude=${lat}&longtitude=${long}`);
  }

  getDoctorDetails(id) {
    return this.http.get(`${this.url}/doctor?doctorId=${id}`);
  }

  getDoctorsAppointmentSlots(id)
  {
    return this.http.get<any>(`${this.url}/appointmentdata/${id}`);
  }
  loginValidation(pho_no) {
    return this.http.get<any>(`${this.url}/login/${pho_no}`);
  }
  UpdateLoginCredentials(pho_no, pin_code) {
    return this.http.get<any>(`${this.url}/forgotpassword/${pho_no}/${pin_code}`);
  }
  getloginDetails() {
    return localStorage.getItem("loginCredentials") ? true :false;
   }
   getDoctorLists(id) {
    return this.http.get(`${this.url}/city/1/${id}`);
  }
 
  getCurrentLocationDoctor(lat, long) {
    return this.http.get<any>(`${this.url}/currentLocationDoctors?latitude=${lat}&longtitude=${long}`);
  }

  getCityDetails(cityId)
  {
    return  this.http.get<any>(`${this.url}/citydetails?cityId=${cityId}`);
  }

  postAppointmentDetails(body)
  {
    return this.http.post<any>(`${this.url}/appointmentPostdata/`,body);
  }
  getAuthDetails() {
    return JSON.parse(localStorage.getItem("loginCredentials"));
  }
  checkExistingMobileNo(mobno){
    return this.http.get<any>(`${this.url}/login?mobileNumber=${mobno}`)
  }
  getPatientAppointmentData(id)
  {
    return this.http.get<any>(`${this.url}/getpatientappointments?id=${id}`)
  }
  cancelPatientAppointment(appointmentid,pwAppointmentId,doctorId)
  {
    return this.http.get<any>(`${this.url}/appointmentcancel?appointmentId=${appointmentid}&appointmentIdPw=${pwAppointmentId}&doctorId=${doctorId}`);
  }

  postpatientDetails(body)
  {
    return this.http.post<any>(`${this.url}/postPatientDetails`,body);
  }

  postReviewDetails(body)
  {
    return this.http.post<any>(`${this.url}/reviews`,body);
  }

  getFiltermapData(body)
  {
    return this.http.get<any>(`${this.url}/mapfilter?${body}`);
  }

  getPrescriptionData(doctorId,patientId,date)
  {
    return this.http.get<any>(`${this.url}/prescription?doctorId=${doctorId}&patientId=${patientId}&appointmentDate=${date}`);
  }

  getFavourites(patientId)
  {
    return this.http.get<any>(`${this.url}/favourites?patientId=${patientId}`);
  }

  checkDomainName(domainName)
  {
    return this.http.get<any>(`${this.url}/domaincheck?domainName=${domainName}`);
  }

  doctorRegistartion(body)
  {
    return this.http.post<any>(`${this.url}/postdoctordetails`,body);
  }

  getLatLan(address)
  {
    return this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyANoBjaYZxWrQNdEZN6C0jdO9yAoDoWvLo`);
  }

  postClinicImage(id,element)
  {
    return this.http.post<any>(`${this.url}/postclinicimages`,{doctorId:id,imageUrl:element.image});
  }

  getPatientImage(patientId)
  {
    return this.http.get<any>(`${this.url}/getPatientImage?patientId=${patientId}`);
  }

  public getIPAddressLatLan(ip) {
    return this.http.get<any>(`http://www.geoplugin.net/json.gp?ip=${ip}`);
    // return this.http.get<any>(`https://tools.keycdn.com/geo.json?host=${ip}`);
  }

}
