import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent, GoogleMapsAPIWrapper } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, SelectMultipleControlValueAccessor } from '@angular/forms';
import { async } from '@angular/core/testing';
import { parse } from 'querystring';
import { Router } from '@angular/router';
import { DoctorviewpageService } from '../doctorviewpage.service';
import { GlobalService } from '../shared/global.service';
import mapStyle from '../shared/googleMapStyles.json';
import { Options } from 'ng5-slider';
import publicIp from 'public-ip';

declare var require: any;
declare var google: any;

@Component({
  selector: 'app-googlemap',
  templateUrl: './googlemap.component.html',
  styleUrls: ['./googlemap.component.css']
})
export class GooglemapComponent implements OnInit {

  title = 'Appointment-tocdor';
  latitude: number;
  longitude: number;
  map: any;
  zoom: number;
  fees: number;
  address: string;
  mark: any[];
  doctorsList: any[];
  private geoCoder;
  public google: any;
  ipaddress: string;
  CurrentLocationBoolean: boolean;
  public currentLocation: string;
  public currentLatitude: number;
  public currentLongitude: number;
  public doctorDetails: any[];
  public doctorDetailsBackup: any[];
  filterData:any[];
  // public markericon: any[];

  //public ip = '192.168.56.1';
  mapstyles = mapStyle;
  public visible: boolean;
  public staticImageVisible: boolean;
  locationId: string;
  reviewFromvalue: number;
  reviewTovalue: number;
  dataLoad:string;
  reviewSlideroptions: Options={
    floor:0,
    ceil:10
  };
  reviewBoolean:boolean;
  feesFromvalue: number;
  feesTovalue: number;
  feesSlideroptions: Options={
    floor:0,
    ceil:10
  };
  feesBoolean:boolean;
  department: string;
  departmentSplit: any[];
  modalRef: any;
  cityId: string;
  Cityname: any;
  legendsBoolean:{
    legend1:boolean,
    legend2:boolean,
    legend3:boolean,
    legend4:boolean,
    legend1Class:string,
    legend2Class:string
    legend3Class:string
    legend4Class:string
  }
  legendsSelect:{
    legend1:boolean,
    legend2:boolean,
    legend3:boolean,
    legend4:boolean,
  }
  constructor(
    private globalService: GlobalService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public router: Router,
    private doctorViewPage: DoctorviewpageService,
    private http: HttpClient
  ) {
    this.visible = false;
    this.CurrentLocationBoolean = false;
    this.staticImageVisible = true;
    this.department='none';
  }
  ngOnInit() {
    this.legendsBoolean={ legend1:false,legend2:false,legend3:false,legend4:false,legend1Class:'btn-outline-primary',legend2Class:'btn-outline-primary',legend3Class:'btn-outline-primary',legend4Class:'btn-outline-primary' };
    this.legendsSelect={ legend1:false,legend2:false,legend3:false,legend4:false };
    this.globalService.mapLoadingInjectVariable
      .subscribe(
        data => {
          if (data !== 'false') {
            if (data !== ' Default Data ') {
              if (data !== '0') {
                this.visible = true;
                this.staticImageVisible = false;
                this.dataLoad='city';
                this.getcityName(data);
              } else {
                this.visible = true;
                this.staticImageVisible = false;
                this.dataLoad='location';
                this.getCurrentAddressData(true);
                this.CurrentLocationBoolean=true;
              }
            }
          } else {
            this.visible = false;
            this.staticImageVisible = false;
          }
        });
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  getCurrentAddressData(bool) {
    if(this.currentLatitude===undefined && this.currentLongitude===undefined)
    {
      this.ipAddressGetting(true);
    }
    else
    {
      if(bool)
      {
        this.globalService.getCurrentLocationDoctor(this.currentLatitude, this.currentLongitude)
        .subscribe((res: any) => {
          console.log(res);
          this.filterDataMethod(res.filterData);
          this.doctorDetails = res.currentLocationdoctors;
          this.latitude = this.currentLatitude;
          this.longitude = this.currentLongitude;
          this.zoom = 12;
          this.CurrentLocationBoolean = true;
          this.markerImageFilter(res.currentLocationdoctors);
        });
      }
    }
    
  }
  filterDataMethod(object)
  {
    if(object.maxReview!==null && object.minReview!==null && object.minReview!==object.maxReview)
    {
      this.reviewBoolean=true;
      this.reviewFromvalue=object.minReview;
      this.reviewTovalue=object.maxReview;
      this.reviewSlideroptions={
        floor: object.minReview,
        ceil: object.maxReview
      };
    }
    else
    { 
      this.reviewFromvalue=0;
      this.reviewTovalue=1;
      this.reviewSlideroptions={
        floor: 0,
        ceil: 1
      };
      this.reviewBoolean=false;
    }

    if(object.maxAmount!==null && object.minAmount!==null && object.minAmount!==object.maxAmount)
    {
      this.feesBoolean=true;
      this.feesFromvalue=object.minAmount;
      this.feesTovalue=object.maxAmount;
      this.feesSlideroptions={
        floor: object.minAmount,
        ceil: object.maxAmount
      };
    }
    else
    { 
      this.feesFromvalue=0;
      this.feesTovalue=1;
      this.feesSlideroptions={
        floor: 0,
        ceil: 1
      };
      this.feesBoolean=false;
    }
    if(object.department!==null)
    {
      this.departmentSplit=object.department.split(',');
    }
    else
    {
      this.departmentSplit=[];
    }
  }
  getcityName(cityId) {
    this.cityId=cityId;
    this.globalService.getDoctorListCityWise(cityId).subscribe((res: any) => {
      this.filterDataMethod(res.filterData);
      this.globalService.getCityDetails(cityId)
        .subscribe((response: any) => {
          this.latitude = response.city.city_latitude;
          this.longitude = response.city.city_longitude;
          this.zoom = 12;
          this.CurrentLocationBoolean = true;
          this.markerImageFilter(res.cityWiseDoctors);
        });
    });
  }

  private setCurrentLocation() {
    if (!this.CurrentLocationBoolean) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.currentLatitude = position.coords.latitude;
          this.currentLongitude = position.coords.longitude;
          this.zoom = 12;
          //  this.getAddress(this.latitude, this.longitude);
        }
        );
      }
      else
      {
        this.ipAddressGetting(false);
      }
    }
  }

  async ipAddressGetting(bool)
  {
    const ip =await publicIp.v4();
    console.log(ip);
    this.globalService.getIPAddressLatLan(ip)
      .subscribe(data=>{
        console.log(data);
        this.currentLatitude = data.geoplugin_latitude;
        this.currentLongitude =data.geoplugin_longitude;
        this.getCurrentAddressData(bool);
      });
  }

  iconsetmap(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('Settings'));
  }

  markerClick(ev: any) {
    this.router.navigate(['doctor', ev.doctorId]);
  }
  previous;
  
  openpopupInfo(infoWindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infoWindow;
  }
  getFilterData()
  {
    let tempdata=`page=${this.dataLoad}`;
    if(this.dataLoad!=='location')
    {
      tempdata+=`&cityId=${this.cityId}`;
    }
    else
    {
      tempdata+=`&lat=${this.latitude}&lan=${this.longitude}`;
    }
    if(this.reviewBoolean)
    {
      tempdata+=`&reviewFrom=${this.reviewFromvalue}&reviewTo=${this.reviewTovalue}`;
    }
    if(this.feesBoolean)
    {
      tempdata+=`&amountFrom=${this.feesFromvalue}&amountTo=${this.feesTovalue}`;
    }
    if(this.department!=='none')
    {
      tempdata+=`&department=${this.department}`;
    }
    this.globalService.getFiltermapData(tempdata)
    .subscribe(res=>{
      this.markerImageFilter(res.doctorDetails);
    });
  }
  markerImageFilter(data)
  {
    this.doctorDetails = data;
    this.doctorDetailsBackup = data;
    data.forEach((key) => {
      this.fees = key.consultantFee;
      if (this.fees > 1500) {
        key.iconcolor = '../../assets/img/flatiIcons/marker3.png'; // indigo color
        this.legendsBoolean.legend4=true;
      } else if (this.fees >= 1001 && this.fees <= 1500) {
        key.iconcolor = '../../assets/img/flatiIcons/marker2.png'; // orange color
        this.legendsBoolean.legend3=true;
      } else if (this.fees >= 501 && this.fees <= 1000) {
        key.iconcolor = '../../assets/img/flatiIcons/marker1.png'; // skyblue color
        this.legendsBoolean.legend2=true;
      } else if (this.fees >= 0 && this.fees <= 500) {
        key.iconcolor = '../../assets/img/flatiIcons/marker.png'; // lightpink color
        this.legendsBoolean.legend1=true;
      }
    });
  }
  changingDepartmentValue(department)
  {
    this.department=department;
  }
  async legendOneClick()
  {
    const tempClass = this.legendsBoolean.legend1Class;
    if(tempClass!=='btn-outline-primary')
    {
      this.legendsBoolean.legend1Class='btn-outline-primary';
      this.legendsSelect={ legend1:false,legend2:false,legend3:false,legend4:false };
    }
    else
    {
      this.legendsBoolean.legend1Class='btn-outline-danger';
      this.legendsSelect={ legend1:true,legend2:false,legend3:false,legend4:false };
    }
    this.legendsBoolean.legend2Class='btn-outline-primary';
    this.legendsBoolean.legend3Class='btn-outline-primary';
    this.legendsBoolean.legend4Class='btn-outline-primary';
    this.legendFilterData();
  }
  async legendTwoClick()
  {
    const tempClass = this.legendsBoolean.legend2Class;
    if(tempClass!=='btn-outline-primary')
    {
      this.legendsBoolean.legend2Class='btn-outline-primary';
      this.legendsSelect={ legend1:false,legend2:false,legend3:false,legend4:false };
    }
    else
    {
      this.legendsBoolean.legend2Class='btn-outline-danger';
      this.legendsSelect={ legend1:false,legend2:true,legend3:false,legend4:false };
    }
    this.legendsBoolean.legend1Class='btn-outline-primary';
    this.legendsBoolean.legend3Class='btn-outline-primary';
    this.legendsBoolean.legend4Class='btn-outline-primary';
    this.legendFilterData();
  }
  async legendThreeClick()
  {
    const tempClass = this.legendsBoolean.legend3Class;
    if(tempClass!=='btn-outline-primary')
    {
      this.legendsBoolean.legend3Class='btn-outline-primary';
      this.legendsSelect={ legend1:false,legend2:false,legend3:false,legend4:false };
    }
    else
    {
      this.legendsBoolean.legend3Class='btn-outline-danger';
      this.legendsSelect={ legend1:false,legend2:false,legend3:true,legend4:false };
    }
    this.legendsBoolean.legend1Class='btn-outline-primary';
    this.legendsBoolean.legend2Class='btn-outline-primary';
    this.legendsBoolean.legend4Class='btn-outline-primary';
    this.legendFilterData();
  }
  async legendFourClick()
  {
    const tempClass = this.legendsBoolean.legend4Class;
    if(tempClass!=='btn-outline-primary')
    {
      this.legendsBoolean.legend4Class='btn-outline-primary';
      this.legendsSelect={ legend1:false,legend2:false,legend3:false,legend4:false };
    }
    else
    {
      this.legendsBoolean.legend4Class='btn-outline-danger';
      this.legendsSelect={ legend1:false,legend2:false,legend3:false,legend4:true };
    }
    this.legendsBoolean.legend1Class='btn-outline-primary';
    this.legendsBoolean.legend3Class='btn-outline-primary';
    this.legendsBoolean.legend2Class='btn-outline-primary';
    this.legendFilterData();
  }

  legendFilterData()
  {
    if(this.legendsSelect.legend1 || this.legendsSelect.legend2 || this.legendsSelect.legend3 || this.legendsSelect.legend4)
    {
      console.log(this.legendsSelect.legend1);
      this.legendsSelect.legend1
      ?
        this.doctorDetails=this.doctorDetailsBackup.filter(item=>item.consultantFee<=500)
      :
        null

      this.legendsSelect.legend2
      ?
        this.doctorDetails=this.doctorDetailsBackup.filter(item=>item.consultantFee>=501 && item.consultantFee<=1000)
      :
        null

      this.legendsSelect.legend3
      ?
        this.doctorDetails=this.doctorDetailsBackup.filter(item=>item.consultantFee>=1001 && item.consultantFee<=1501)
      :
        null

        this.legendsSelect.legend4
        ?
          this.doctorDetails=this.doctorDetailsBackup.filter(item=>item.consultantFee>=1501)
        :
          null
      
    }
    else
    {
      this.doctorDetails=this.doctorDetailsBackup;
    }
  }
}
