import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../shared/global.service';
import { ActivatedRoute,Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NavigationService } from '../shared/navigation/navigation.service';
import { FormGroup, FormBuilder ,Validators } from '@angular/forms';

interface storageData {
  mobileNumber:string,
  profilePicture:string,
  patientName:string,
  lastName:string,
  dob:Date,
  bloodGroup:string,
  emailId:string,
  address:string,
  city:string,
  state:string,
  zipCode:string,
  country:string,
  image :string,
  patientId:number
}

@Component({
  selector: 'app-patientprofile',
  templateUrl: './patientprofile.component.html',
  styleUrls: ['./patientprofile.component.css']
})

export class PatientprofileComponent implements OnInit {
  profilePicture:string;
  cityList:any[];
  storageData:storageData;
  profileForm:FormGroup;
  state:string;
  city:string;
  toast:boolean=false;

  constructor(private fb:FormBuilder,private globalService:GlobalService,private route:ActivatedRoute,private router:Router,private navigationService: NavigationService) {  
    this.storageData=JSON.parse(localStorage.getItem('loginCredentials'));
    this.profileForm=this.fb.group({
      profilePicture1:[''],
      patientName:[''],
      lastName:[''],
      dob:[''],
      bloodGroup:[''],
      emailId:[''],
      mobileNumber:[''],
      address:[''],
      country:[''],
      state:[''],
      city:[''],
      zipCode:[''],
      profilePicture:[''],
      patientId:['']
    })
  }

  ngOnInit() {
    this.loadData();
    this.profileForm.patchValue({'patientName':this.storageData.patientName});
    this.profileForm.patchValue({'lastName':this.storageData.lastName});
    this.profileForm.patchValue({'dob':this.storageData.dob});
    this.profileForm.patchValue({'bloodGroup':this.storageData.bloodGroup});
    this.profileForm.patchValue({'emailId':this.storageData.emailId});
    this.profileForm.patchValue({'mobileNumber':this.storageData.mobileNumber});
    this.profileForm.patchValue({'address':this.storageData.address});
    this.profileForm.patchValue({'country':'India'});
    this.profileForm.patchValue({'state':this.storageData.state});
    this.profileForm.patchValue({'city':this.storageData.city});
    this.profileForm.patchValue({'zipCode':this.storageData.zipCode});
  }
  
  loadData()
  {
    this.globalService.getPatientImage(this.storageData.patientId)
    .subscribe(data=>{
      if(data.status==='success')
      {
        this.profilePicture=data.profilePicture;
      } 
      else
      {
        console.log('Error occured while Profile picture getting');
        this.profilePicture='https://cdn1.vectorstock.com/i/thumb-large/75/85/default-placeholder-doctor-half-length-portrait-vector-20847585.jpg';
      }
      
    })
    this.globalService.getCityList()
    .subscribe(data=>{
      if(data.status==='success')
      {
        this.cityList=data.city;
      }
      else
      {
        console.log('Error occured while retriving City List Data');
      }
    })  
  }

  handleProfilePictureInput(files)
  {
    let file = files.item(0);
    if (file) {
      if (file.size <= 256000) {
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          let reader = new FileReader();
          reader.onload = this._handleProfilePictureLoaded.bind(this);
          reader.readAsBinaryString(file);
        }
      }
    }
  }

  _handleProfilePictureLoaded(readerEvt)
  {
    var binaryString = readerEvt.target.result;
    this.profilePicture = 'data:image/jpg;base64,' + btoa(binaryString);
  }

  submit()
  {
    this.profileForm.patchValue({'profilePicture':this.profilePicture});
    this.profileForm.patchValue({'patientId':this.storageData.patientId});
    console.log(this.profileForm.value);
    this.globalService.postpatientDetails(this.profileForm.value)
      .subscribe(res=>{
          this.toast=true;
          delete res.data.profilePicture;
          localStorage.setItem("loginCredentials",JSON.stringify(res.data));
          this.navigationService.navigationPageLoadData.next(true);
          setTimeout(()=>{
            this.toast=false;
          },3000)
      })
  }
}
