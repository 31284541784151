import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatientprofileComponent } from './patientprofile.component';
import { CityListResolverService } from '../shared/globalResolver.service';


const routes: Routes = [{
  path:'',
  component:PatientprofileComponent,
  resolve:{
    city:CityListResolverService
  }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers:[CityListResolverService]
})
export class PatientprofileRoutingModule { }
