import { NgModule } from '@angular/core';
import { Routes, RouterModule ,CanActivate } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { DoctordetailComponent } from './doctordetail/doctordetail.component';
import { BookappointmentComponent } from './bookappointment/bookappointment.component';
import {CanActiveService} from './login-page/login.service';
import {CheckcredentialsService} from './shared/checkcredentials.service';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'search',
    pathMatch: 'full',
  },
  {
    path: 'search',
    loadChildren: () => import ('./search-doctor/search-doctor.module').then(s => s.SearchDoctorModule)
  },
  {
    path: 'doctor/:id',
    loadChildren: () => import ('./doctordetail/doctordetail.module').then(d => d.DoctordetailModule)
  },
  {
    path: 'appointment/:id',
    canActivate:[CheckcredentialsService],
    loadChildren: () => import ('./bookappointment/bookappointment.module').then(d => d.BookappointmentModule)
  },
  {
    path: 'login',
    canActivate: [CanActiveService],
    loadChildren: () => import ('./login-page/login-page.module').then(s => s.LoginPageModule)
  },
  {
    path:'forgotpin',
    loadChildren: () => import ('./forgotpin/forgotpin.module').then(f => f.ForgotpinModule)
  },
  {
    path:'patientDashboard',
    canActivate:[CheckcredentialsService],
    loadChildren: () => import ('./patient-dashboard/patient-dashboard.module').then(f => f.PatientDashboardModule)
  },
  {
    path:'patientProfile',
    canActivate:[CheckcredentialsService],
    loadChildren: () => import('./patientprofile/patientprofile.module').then(f=>f.PatientprofileModule)
  },
  {
    path:'favourites',
    canActivate:[CheckcredentialsService],
    loadChildren: () => import('./favourites/favourites.module').then(f=>f.FavouritesModule)
  },
  {
    path:'doctorRegistration',
    loadChildren: () => import('./doctorregister/doctorregister.module').then(f=>f.DoctorregisterModule)
  },
  {
    path:'doctorRegistrationWebView',
    loadChildren: () => import('./doctorregisterwebview/doctorregisterwebview.module').then(f=>f.DoctorregisterwebviewModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
