import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PatientprofileRoutingModule } from './patientprofile-routing.module';
import { PatientprofileComponent } from './patientprofile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [PatientprofileComponent],
  imports: [
    CommonModule,
    FormsModule,
    PatientprofileRoutingModule,
    ReactiveFormsModule
  ],
  exports:[PatientprofileComponent]
})
export class PatientprofileModule { }
