import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DoctorregisterRoutingModule } from './doctorregister-routing.module';
import { DoctorregisterComponent } from './doctorregister.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module,RecaptchaFormsModule } from 'ng-recaptcha';
import { RecaptchaModule } from 'ng-recaptcha';
import {MatStepperModule} from '@angular/material/stepper';

@NgModule({
  declarations: [DoctorregisterComponent],
  imports: [
    CommonModule,
    DoctorregisterRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaModule,
    MatStepperModule
  ],
  exports:[DoctorregisterComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class DoctorregisterModule { }
