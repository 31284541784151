import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { GlobalService } from '../shared/global.service';
import { CustomValidators } from 'ngx-custom-validators';
import { NavigationService } from '../shared/navigation/navigation.service';

interface userDetails {
  patientId: number
  patientName: string
  mobileNumber: string
  emailId: string
  accessStatus: string
  pinCode: string
  profilePicture: string
  createdAt: string
  lastlogin: string
  backupCode: string
  updatedAt: string
  otp: string
};

@Component({
  selector: 'app-forgotpin',
  templateUrl: './forgotpin.component.html',
  styleUrls: ['./forgotpin.component.css']
})
export class ForgotpinComponent implements OnInit {
  forgotForm: FormGroup;
  mobileNumber: string = '';
  otp: string = '';
  isMobileVisible: boolean = true;
  isOtpVisible: boolean = false;
  isPinVisible: boolean = false;
  isConfirmVisible: boolean = false;
  userDetails: userDetails;
  recaptcha: string;
  constructor(
    private fb: FormBuilder,
    private globalService: GlobalService,
    private router: Router,
    private navigationService: NavigationService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    let pincodevalidation = new FormControl('', [Validators.required, Validators.minLength(4)]);
    let otpvalidation = new FormControl('', [Validators.required, Validators.minLength(4)]);
    let confirmpasswordvalidation = new FormControl('', [Validators.required, Validators.minLength(4), CustomValidators.equalTo(pincodevalidation)])

    this.forgotForm = this.fb.group({
      mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      otp: otpvalidation,
      pin: pincodevalidation,
      confirmPin: confirmpasswordvalidation
    });
  }

  otpChange(otp) {
    if (otp.length === 4 && otp === this.userDetails.otp.toString()) {
      this.isMobileVisible = this.isOtpVisible = false;
      this.isPinVisible = this.isConfirmVisible = true;
    }
  }

  mobileNumberChange(number) {
    if (number.length === 10) {
      this.globalService.checkExistingMobileNo(number)
        .subscribe(data => {
          if (data.status === 'success') {
            this.userDetails = data.userDetails;
            this.isMobileVisible = this.isPinVisible = this.isConfirmVisible = false;
            this.isOtpVisible = true;
            this.forgotForm.controls['otp'].setValidators([CustomValidators.equal(data.userDetails.otp.toString())])
          }
          else {
            this.forgotForm.controls['mobileNumber'].setErrors({ 'invalid': 'Mobile Number is Not Registered.' });
          }
        });
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  get geterrors() {
    return this.forgotForm.controls;
  }
  resolved(captchaResponse: string) {
    this.recaptcha = captchaResponse;
  }
  onsubmit() {
    if (this.forgotForm.status === 'VALID') {
      if (this.recaptcha !== null && this.recaptcha !== undefined) {
        this.globalService.UpdateLoginCredentials(this.forgotForm.value.mobileNumber, this.forgotForm.value.pin)
          .subscribe(data => {
            if (data.status === 'success') {
              delete data.updatedUserDetails[0].profilePicture;
              localStorage.setItem("loginCredentials", JSON.stringify(data.updatedUserDetails[0]));
              this.navigationService.navigationPageLoadData.next(true);
              window.history.go(-2);
            }
            else {
              console.log('Error Occured while Posting credential details');
            }
          });
      }
    }
  }
}
