import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchDoctorRoutingModule } from './search-doctor-routing.module';
import { SearchDoctorComponent } from './search-doctor.component';
import { MatFormField, MatFormFieldModule, MatAutocompleteModule, MatOptionModule, MatInputModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GooglemapModule } from '../googlemap/googlemap.module';

@NgModule({
  declarations: [SearchDoctorComponent],
  imports: [
    CommonModule,
    SearchDoctorRoutingModule,
    FormsModule,
    NgbModule,
    GooglemapModule

  ],
  exports: [SearchDoctorComponent]
  
})
export class SearchDoctorModule { }
