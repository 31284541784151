import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../shared/global.service';
import { Router } from '@angular/router';
interface storageData {
  patientId:number,
}
interface reviewdAppointment {
  patientId:number,
  doctorId:number,
  appointmentId:number
}
@Component({
  selector: 'app-patientappointmentdata',
  templateUrl: './patientappointmentdata.component.html',
  styleUrls: ['./patientappointmentdata.component.css']
})
export class PatientappointmentdataComponent implements OnInit {
  upcomingAppointments:any[];
  completedAppointments:any[];
  canceledAppointments:any[];
  storageData:storageData;
  reviewdAppointment:reviewdAppointment;
  visible:boolean;
  review:string;
  prescriptionLoading:boolean=true;
  prescriptionData:any[];

  constructor(private globalService:GlobalService,private router:Router) {
    this.storageData=JSON.parse(localStorage.getItem("loginCredentials"));
    this.visible=false;
  }

  ngOnInit() {
    this.getAppointmentData()
  }

  getAppointmentData()
  {
    this.globalService.getPatientAppointmentData(this.storageData.patientId)
      .subscribe(data=>{
        if(data.status==='success')
        {
          this.upcomingAppointments=data.patientAppointmentData.filter(item => item.appointmentStatus===1);
          this.completedAppointments=data.patientAppointmentData.filter(item => item.appointmentStatus===2);
          this.canceledAppointments=data.patientAppointmentData.filter(item => item.appointmentStatus===0);
          this.visible=true;
        }
        else
        {
          console.log('Error Occured while retriving patient appointments data');
        }
    }) 
  }
  convertMinsToTime = (mins) => {
    let hours = Math.floor(mins / 60);
    let minutes = mins % 60;
    let hoursNumber = hours < 10 ? '0' + hours : hours;
    let minutesNumber = minutes < 10 ? '0' + minutes : minutes;
    return `${hoursNumber}:${minutesNumber}`;
  }

  cancelAppointment(appointment)
  {
    if(confirm("Are you sure to Cancel This Apppointment")) 
    {
      this.globalService.cancelPatientAppointment(appointment.appointmentId,appointment.pwAppointmentId,appointment.doctorId)
      .subscribe(res=>{
        this.visible=false;
        this.getAppointmentData();
      });
    }
  }

  bookAgain(appointment)
  {
    this.router.navigate(['/appointment',appointment.doctorId]);
  }

  reviewData(appointment)
  {
    this.reviewdAppointment=appointment;
  }

  postReview()
  {
    const tempData = {
      doctorId:this.reviewdAppointment.doctorId,
      patientId:this.reviewdAppointment.patientId,
      comments:this.review,
      appointmentId:this.reviewdAppointment.appointmentId
    }
    this.globalService.postReviewDetails(tempData)
    .subscribe(res=>{
      this.visible=false;
      this.getAppointmentData();
    });
  }

  async getPrescription(data)
  {
    this.prescriptionLoading=false;
    this.globalService.getPrescriptionData(data.doctorId,data.opId,data.appointmentDate)
    .subscribe(res=>{
        res.status==='success'
        ?
          this.prescriptionData=res.prescription[0]
        :
          null
    
          this.prescriptionLoading=true;
    })

  }

}
